@mixin gap($col:0) {
  flex: #{$col} 0 calc(100% / 12 * #{$col});
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}

$cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

@each $col in $cols {
  .col-#{$col} {
    @include gap($col);
  }
}

@media only screen and (max-width: 600px) {
  @each $col in $cols {
    .col-xs-#{$col} {
      @include gap($col);
    }
  }
}

@media only screen and (min-width: 600px) {
  @each $col in $cols {
    .col-sm-#{$col} {
      @include gap($col);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  @each $col in $cols {
    .col-md-#{$col} {
      @include gap($col);
    }
  }
}

@media only screen and (min-width: 1200px) {
  @each $col in $cols {
    .col-lg-#{$col} {
      @include gap($col);
    }
  }
}


