@use '@angular/material' as mat;

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

//PALETTE
// Paleta primária (com base na cor #022A3A)
$mat-primary: (
  50: #e6f1f4,
  100: #cce3ea,
  200: #99c7d4,
  300: #66abbf,
  400: #338fa9,
  500: #022A3A, // Cor principal
  600: #022535,
  700: #021f2e,
  800: #021a28,
  900: #01101d,

  // Contraste
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff, // Contraste com a cor principal
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

// Paleta de acento (com base na cor #00FFCC)
$mat-secondary: (
  50: #e0fff9,
  100: #b3ffef,
  200: #80ffe5,
  300: #4dffdb,
  400: #1affd1,
  500: #00FFCC, // Cor principal de acento
  600: #00e6b8,
  700: #00cc9f,
  800: #00b38b,
  900: #008566,

  // Contraste
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000, // Contraste com a cor principal de acento
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff
  )
);


$body-font: 'verdana', sans-serif;
$heading-font: 'verdana', sans-serif;

$primary: mat.get-color-from-palette($mat-primary, 500);
$secondary: mat.get-color-from-palette($mat-secondary, 500);

$body-color: #eef5f9;
$heading-text: #455a64;
$body-text: #414141;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$theme-color: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;
